<template>
  <CContainer>
    <CRow v-for="(category) in paymentMethodCategory" :key="category">
      <CCol>
        <CCard>
          <CCardHeader class="p-3 px-4">
            <CRow>
              <h5 id="traffic" class="card-title mb-0 titleCategory">{{ category }}</h5>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <div
                v-for="(item) in paymentMethods[category]"
                :key="item.id"
                class="col-md-4 col-lg-3 mb-4"
              >
                <CCard :class="`${item.activation_status}`">
                  <CCardHeader class="p-3 px-4 payment-method-header">
                    <div class="header-left">
                    <CSwitch
                            class="mr-1 activate-payment-method"
                            color="info"
                            :id="`switch_${item.id}`"
                            :checked="item.is_enabled"
                            shape="pill"
                            :disabled="isSwitchDisabled(item.activation_status)"
                            variant="3d"/>
                    <span class="payment-method-name">{{ item.name }}</span>
                    </div>
                    <div class="header-right">
                      <img :src="getActivationStatusRibbonImageLink(item.activation_status)">
                    </div>
                  </CCardHeader>
                  <CCardBody style="text-align: center;">
                    <img :src="require(`./../../../src/assets/img/${item.logo}`)" alt="default.svg" class="card-image"/>
                  </CCardBody>
                </CCard>
              </div>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CButton class="submit-button"
      @click.prevent="saveConfirmationModal = true"
    >{{ $t("COMMON.SAVE") }}</CButton>

    <CModal
      :title="$t('PROMOS.EDIT_CONFIRM')"
      color="light"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="saveConfirmationModal"
    >
      <CContainer>
        <label class="modal-msg">{{ $t("SETTINGS.PAY_METHODS_UPDATE_CONFIRM")}}</label>
      </CContainer>
      <template #footer>
        <CButton
          @click="saveConfirmationModal = false"
          color="lightborder"
        >{{ $t("COMMON.CANCEL") }}</CButton>
        <CButton @click.prevent="updatePayMethods()" color="lightborder">{{ $t("COMMON.OK") }}</CButton>
      </template>
    </CModal>


    <CModal title="Modal title" color="secondary" :show.sync="exportModal">
      <vue-json-to-csv :json-data="paymentMethods_export">
        <CButton color="durianprimary">
          <CIcon name="cil-cloud-download" />&nbsp;Download CSV
        </CButton>
      </vue-json-to-csv>
    </CModal>

  </CContainer>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
import {
  listPaymentMethods,
  updatePaymentMethods
} from "@/api/paymentMethods.api.js";

export default {
  components: {
    VueJsonToCsv
  },
  name: "PaymentMethods",
  activation_status: "",
  is_enabled: "",
  data() {
    return {
      saveConfirmationModal: false,
      exportModal: false,
      paymentMethods: [],
      paymentMethods_export: [],
      paymentMethodCategory: ["CARD", "VA", "EWALLET"]
    };
  },
  methods: {
    getPayMethods() {
      listPaymentMethods()
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.paymentMethods_export = response.data.data.payment_methods;
            this.paymentMethods = this.formatPaymentMethods(
              response.data.data.payment_methods
            );
          }
        })
        .catch(this.handleAPIError);
    },
    getActivationStatusRibbonImageLink(activation_status) {
      let images = require.context('./../../../src/assets/img/', false, /\.svg$/)
      let image = "activation_" + activation_status;
      return images('./' + image + ".svg")
    },
    getCSVLables() {     
      return this.CSVLables;
    },
    formatPaymentMethods(data) {
      data.forEach(element => {
        element.logo = this.getStaticImagePath(element.logo);
      });
      var groupBy = function(array, key) {
        return array.reduce(function(result, pay_method) {
          (result[pay_method[key]] = result[pay_method[key]] || []).push(
            pay_method
          );
          return result;
        }, {});
      };

      return groupBy(data, "category");
    },
    getPayMethodsByCategory(category) {
      return this.paymentMethods[category];
    },
    getStaticImagePath(path) {
      if (path == "") {
        path = "default.svg"
      }
      let filename = path.split("/").pop();
      return filename
    },
    checkIfSelected(category) {
      if (this.validateRequiredField(this.paymentMethods)) {
        let isActive = true;
        this.paymentMethods[category].forEach(element => {
          if (!element.is_active) {
            isActive = false;
          }
        });
        return isActive;
      }
    },
    selectAll(category, e) {
      if (this.validateRequiredField(this.paymentMethods)) {
        for (let i = 0; i < this.paymentMethods[category].length; i++) {
          this.paymentMethods[category][i].is_active = e.target.checked;
        }
      }
    },
    cleanCSVData() {
      this.paymentMethods_export = JSON.parse(JSON.stringify(this.paymentMethods_export));      
    },
    getPaymentMethodsForExport() {
      this.cleanCSVData();
    },
    checkActive(category, e) {
      if (this.validateRequiredField(this.paymentMethods)) {
        for (let i = 0; i < this.paymentMethods[category].length; i++) {
          if (this.paymentMethods[category][i].unique_id === e.target.id) {
            this.paymentMethods[category][i].is_active = e.target.checked;
          }
        }
      }
    },
    updatePayMethods() {
      updatePaymentMethods(this.formatUpdateMethods())
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.paymentMethods = this.formatPaymentMethods(
              response.data.data.payment_methods
            );
            this.saveConfirmationModal = false;
            this.showSuccessToaster(this.$t("COMMON.UPDATED"));
          }
        })
        .catch(this.handleAPIError);
    },
    formatUpdateMethods() {
      let updatedPaymentMethods = [];
      this.paymentMethodCategory.forEach(category => {
        this.paymentMethods[category].forEach(method => {
          let currentState = document.getElementById("switch_" + method.id).checked;
          let updateMethod = {
            id: method.id,
            is_enabled: currentState,
            activation_status: method.activation_status,
            is_available: method.is_available
          };
          updatedPaymentMethods.push(updateMethod);
        });
      });
      let data = {
        payment_methods: updatedPaymentMethods
      };
      return data;
    },
    isSwitchDisabled(activation_status) {
      return activation_status !== "active"
    },
  },
  created() {
    this.getPayMethods();
  }
};
</script>
<style>
.titleCategory {
  width: 90%;
}

.payment-method-header {
  padding-right: 0 !important;
}

.payment-method-name {
  margin-left: 0.5rem;
  font-weight: 700;
}

.inactive {
  filter: grayscale(100%);
  filter: "gray";
}

.header-right {
  z-index: 100;
  float: right;
}

.header-left {
  top: 6px;
  position: absolute;
}

.card-image {
  height: 4rem;
}

.submit-button {
  width: 10rem;
  height: 3rem;
  font-weight: 700;
  font-size: 18px;
  background: #725CF6;
  color: white;
}
</style>
