<template>
<div v-page-access="permissions.settingsView">
  <CNav variant="tabs">
    <CNavItem
      v-on:click.native="changeTab(tabCheckout)"
      :active="activeTab === tabCheckout"
      >{{ $t("SETTINGS.CHECKOUT") }}</CNavItem
    >
    <CNavItem
      v-on:click.native="changeTab(tabAPIKeys)"
      :active="activeTab === tabAPIKeys"
      >{{ $t("SETTINGS.APIKEYS") }}</CNavItem
    >
    <CNavItem
      v-on:click.native="changeTab(tabWebhooks)"
      :active="activeTab === tabWebhooks"
      >{{ $t("SETTINGS.WEBHOOKS") }}</CNavItem
    >
    <CNavItem
      v-on:click.native="changeTab(tabPayMethods)"
      :active="activeTab === tabPayMethods"
      >{{ $t("SETTINGS.PAY_METHODS") }}</CNavItem
    >
  </CNav>
  <CContainer class="mt-3">
    <CRow class="p-0">
      <CContainer class="settings-container">
        <ApiKeys v-show="activeTab === tabAPIKeys" />
        <Webhooks v-show="activeTab === tabWebhooks" />
        <CheckoutConfig v-show="activeTab === tabCheckout" />
      </CContainer>
    </CRow>
    <CRow class="p-0">
      <CContainer>
        <PaymentMethods v-show="activeTab === tabPayMethods" />
      </CContainer>
    </CRow>
  </CContainer>
</div>
</template>
<script>
import ApiKeys from "./ApiKeys";
import PaymentMethods from "./PaymentMethods";
import Webhooks from "./webhooks/Webhooks";
import CheckoutConfig from "@/views/settings/CheckoutConfig";

export default {
  name: "Settings",
  components: {
    CheckoutConfig,
    ApiKeys,
    PaymentMethods,
    Webhooks,
  },
  data() {
    return {
      tabCheckout: "checkout",
      tabAPIKeys: "apiKeys",
      tabPayMethods: "payMethods",
      tabWebhooks: "webhooks",
      activeTab: "",
    };
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
      this.$router.push({ query: Object.assign({}, this.$route.query, { activeTab: tab }) });
    },
  },
  mounted() {
    let activePage = this.tabCheckout;
    const arrayTab = [this.tabCheckout, this.tabAPIKeys, this.tabPayMethods, this.tabWebhooks];
    if (this.$route.query.activeTab !== undefined && arrayTab.includes(this.$route.query.activeTab)) {
        activePage = this.$route.query.activeTab;
    }
    this.activeTab = activePage;
  },
};
</script>
<style scoped>
.settings-container {
  padding-top: 10px;
}
</style>