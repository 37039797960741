<template>
  <div>
    <div class="middle-content">
      <div class="middle-content-text d-flex flex-row justify-content-between">
        <span class="margin-top">{{
          $t("CUSTOMIZATION.SHIPPING_ADDRESS")
        }}</span>
        <button :style="dynamicButtonColor()">
          {{ $t("CUSTOMIZATION.ADD_ADDRESS") }}
        </button>
      </div>
    </div>
    <div class="middle-content">
      <div class="middle-content-text d-flex flex-row justify-content-between">
        <span class="margin-top">Promo</span
        ><button :style="dynamicButtonColor()">
          {{ $t("CUSTOMIZATION.ADD_PROMO") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutAdditionals",
  props: {
    dynamicButtonColor: Function,
  },
};
</script>
<style scoped>
.middle-content {
  font-size: 14px;
  font-weight: bold;
  margin-top: 7px;
  background: white;
  height: 50px;
  position: relative;
  top: -30px;
}
button {
  width: 32%;
  height: 1.7rem;
  border-radius: 0.2rem;
  font-weight: bold;
  margin-right: 5%;
  font-size: 88%;
  margin-top: 12px;
}
.margin-top {
  margin-top: 15px;
}
.middle-content-text {
  margin-left: 5%;
}
</style>
