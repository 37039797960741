<template>
  <div class="custom-card">
      <checkout-head />
      <checkout-purchase-info :dynamicButtonColor="dynamicButtonColor" />
      <checkout-additionals :dynamicButtonColor="dynamicButtonColor" />
      <checkout-amount-info />
      <checkout-footer :dynamicButtonColor="dynamicButtonColor" />
      <div class="bottom-margin" />
  </div>
</template>

<script>
import CheckoutPurchaseInfo from "./CheckoutPurchaseInfo.vue";
import CheckoutAdditionals from "./CheckoutAdditionals.vue";
import CheckoutAmountInfo from "./CheckoutAmountInfo.vue";
import CheckoutFooter from "./CheckoutFooter.vue";
import CheckoutHead from "./CheckoutHead.vue";

export default {
  components: {
    CheckoutPurchaseInfo,
    CheckoutAdditionals,
    CheckoutAmountInfo,
    CheckoutFooter,
    CheckoutHead,
  },
  name: "Checkout",
  methods: {
    setButtonTextColor() {
      const { r, g, b } = this.$store.getters.getButtonAndLinkColor;
      var textColour = (r * 299 + g * 587 + b * 114) / 1000;
      return textColour >= 128 ? "black" : "white";
    },
    dynamicButtonColor() {
      const hexColor = this.rgbToHex(this.$store.getters.getButtonAndLinkColor);
      return {
        color: this.setButtonTextColor(),
        background: hexColor,
        border: `1px solid ${hexColor}`,
      };
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 760px) {
  .custom-card {
    width: 100%;
    margin-left: 4%;
    background: #e5e5e5;
    border: 1px solid #dedaf1;
    border-radius: 15px;
    position: relative;
  }
}
.custom-card {
  width: 94%;
  background: #e5e5e5;
  border: 1px solid #dedaf1;
  border-radius: 15px;
  position: relative;
}
.bottom-margin {
  margin-bottom: -23px;
}
</style>
