<template>
  <div>
    <div class="holder">
      <div class="cross pointer-on-hover">
        <h5 class="cross-sign" @click="removeImage()">&times;</h5>
      </div>
      <img
        class="holder-image"
        :width="$store.getters.getLogoWidth"
        :height="$store.getters.getLogoHeight"
        :src="$store.getters.getLogoUrl"
      />
    </div>
  </div>
</template>

<script>
import * as types from "@/store/mutation-types.js";

export default {
  name: "ImageHolder",
  methods: {
    removeImage() {
      this.$store.commit(types.setLogoUrl, null);
    },
  },
};
</script>

<style scoped>
.holder {
  border: 1px solid #dfdfdf;
  width: 95%;
  height: 45px;
  box-shadow: 0px 2px 3px rgba(174, 174, 174, 0.25);
  border-radius: 4px;
  margin-left: 15%;
}
.holder-image {
  position: relative;
  top: 7%;
}
.cross {
  position: relative;
  left: 90%;
  bottom: 15px;
  height: 25px;
  width: 25px;
  border: 1px solid #dfdfdf;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 2px 3px rgba(174, 174, 174, 0.25);
  display: inline-block;
}
.cross-sign {
  margin-left: 30%;
}
</style>