<template>
  <CContainer>
    <CModal
      :title="$t('WEBHOOKS.EDIT_HEADER')"
      color="light"
      size="lg"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="showEditModal"
    >
      <CContainer>
        <ValidationObserver ref="validationObserver">
          <CForm>
            <CRow>
              <CCol tag="label" sm="3" class="col-form-label">
                {{ $t("WEBHOOKS.LABELS.NAME") }}
              </CCol>
              <ValidationProvider
                name="Webhook Name"
                ref="Webhook Name"
                rules="requiredName"
                v-slot="{ errors }"
                class="form-group col-6"
              >
                <b-form-input
                  class="col-12 mb-0"
                  id="webhooklabel"
                  name="webhooklabel"
                  v-model="webhook.name"
                  type="text"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider></CRow
            >
            <CRow>
              <CCol tag="label" sm="3" class="col-form-label">
                {{ $t("WEBHOOKS.LABELS.URL") }}
              </CCol>
              <ValidationProvider
                name="Webhook Url"
                ref="Webhook Url"
                rules="requiredURL"
                v-slot="{ errors }"
                class="form-group col-sm-9"
              >
                <b-form-input type="text" v-model="webhook.url" horizontal />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </CRow>

            <CRow>
              <CCol tag="label" sm="3" class="col-form-label">
                {{ $t("WEBHOOKS.LABELS.EVENT") }}
              </CCol>
              <ValidationProvider
                name="Webhook Event"
                ref="Webhook Event"
                rules="requiredEvent"
                v-slot="{ errors }"
                class="col-sm-9"
              >
                <b-form-select
                  v-model="webhook.event"
                  :options="events"
                  class="col-12"
                ></b-form-select>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </CRow>
            <CRow>
              <CCol tag="label" sm="3" class="col-form-label">
                {{ $t("WEBHOOKS.LABELS.PRIMARY") }}
              </CCol>
              <CContainer class="col-8">
                <b-form-checkbox
                  name="Webhook IsPrimary"
                  v-model="webhook.is_primary"
                  class="p-2 col-8"
                >
                </b-form-checkbox>
              </CContainer>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CContainer>
      <template #footer>
        <CButton @click.prevent="validateWebhook" color="durianprimary">{{
          $t("COMMON.SAVE")
        }}</CButton>
      </template>
    </CModal>
    <CModal
      :title="$t('COMMON.EDIT_CONFIRM')"
      color="light"
      :no-close-on-backdrop="false"
      :centered="true"
      :show.sync="editConfirmationModal"
    >
      <CContainer>
        <label class="modal-msg">{{ $t("WEBHOOKS.EDIT_CONFIRM_MSG") }} </label>
      </CContainer>
      <template #footer>
        <CButton @click="editConfirmationModal = false" color="lightborder">{{
          $t("COMMON.CANCEL")
        }}</CButton>
        <CButton @click.prevent="updateWebhook()" color="lightborder">{{
          $t("COMMON.OK")
        }}</CButton>
      </template>
    </CModal>
  </CContainer>
</template>

<script>
import "./../../../validations/validationRules.js";

import { extend } from "vee-validate";
import { i18n } from "./../../../main.js";
import { getWebhook, updateWebhook } from "@/api/webhooks.api.js";

export default {
  name: "EditWebhook",
  props: ["editWebhookModal", "id"],
  data() {
    return {
      webhook: {},
      events: [],
      editConfirmationModal: false,
    };
  },
  computed: {
    showEditModal: {
      get: function () {
        return this.editWebhookModal;
      },
      set: function (value) {
        this.$emit("update:editWebhookModal", value);
      },
    },
  },
  methods: {
    async validateWebhook() {
      await this.$refs.validationObserver.validate().then((result) => {
        if (result) {
          this.editConfirmationModal = true;
        }
      });
    },
    getWebhookDetails() {
      getWebhook(this.id)
        .then((response) => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.webhook = response.data.data;
          }
        })
        .catch(this.handleAPIError);
    },
    updateWebhook() {
      updateWebhook(this.id, this.webhook)
        .then((response) => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.showSuccessToaster(this.$t("WEBHOOKS.TOASTS.UPDATED"));
            this.editConfirmationModal = false;
            this.showEditModal = false;
            this.$emit("updatelist");
          }
        })
        .catch(this.handleAPIError);
    },
    getEvents() {
      this.events = this.getWebhookEvents();
    },
  },
  mounted() {
    this.getWebhookDetails();
    this.getEvents();
  },
};
</script>
<style>
</style>
