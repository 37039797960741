<template>
<CCard>
  <CCardHeader class="mt-2">
    <h5>{{ $t("SETTINGS.APIKEYS") }}</h5>
  </CCardHeader>
  <CCardBody>
    <CDataTable class="col-md-12 p-0" hover :items="apikeys" :fields="fields">
      <template #name="data">
        <td>{{ getName(data.item.secret_key) }}</td>
      </template>
      <template #secret_key="data">
        <td>
          {{ data.item.maskedKey }}
          <CButton
            type="Copy"
            class="copy-btn ml-3"
            @click.prevent="copyToClipboard(data.item.secret_key,'API key copied')"
          ><CIcon name="cil-copy"/>&nbsp;{{ $t("COMMON.COPY_BUTTON") }}</CButton>
        </td>
      </template>
      <template #regenerate="data">
        <td>
          <div class="float-right">
            <CButton
              type="Regenerate"
              class="regen-btn px-1"
              @click.prevent="regenerateKey(data.item.secret_key)"
            ><CIcon name="cil-sync"/>&nbsp;{{ $t("APIKEYS.REGENERATE") }}</CButton>
          </div>
        </td>
      </template>
    </CDataTable>
  </CCardBody>
</CCard>
</template>

<script>
import { listApiKeys, regenerateApiKey } from "@/api/apikeys.api.js";

export default {
  name: "ApiKeys",
  data() {
    return {
      fields: [
        { key: "name", label: this.$t("APIKEYS.LABELS.NAME") },
        {
          key: "secret_key",
          label: this.$t("APIKEYS.LABELS.API_KEY"),
        },
        { key: "regenerate", label: "" }
      ],
      apikeys: []
    };
  },
  methods: {
    getApiKeys() {
      listApiKeys()
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.apikeys = response.data.data;
            this.apikeys.forEach(keyInfo => {
              keyInfo.maskedKey = this.getMaskedKey(keyInfo.secret_key);
            });
          }
        })
        .catch(this.handleAPIError);
    },
    getName(secret_key) {
      let env = this.getKeyEnv(secret_key);
      switch (env) {
        case "live":
          return this.$t("APIKEYS.LABELS.LIVE");
        case "test":
          return this.$t("APIKEYS.LABELS.SANDBOX");
        default:
          return env;
      }
    },
    getMaskedKey(secret_key) {
      let res = secret_key.replace(
        secret_key.substring(11, secret_key.length - 1),
        "X".repeat(secret_key.length - 11 - 1)
      );
      return res;
    },
    regenerateKey(secret_key) {
      let env = this.getKeyEnv(secret_key);
      regenerateApiKey(env)
        .then(response => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            let updatedKey = response.data.data;
            updatedKey.maskedKey = this.getMaskedKey(updatedKey.secret_key);
            let index = this.apikeys.findIndex(x => x.id === updatedKey.id);
            this.$set(this.apikeys, index, updatedKey);
            this.showSuccessToaster("API key regenerated");
          }
        })
        .catch(this.handleAPIError);
    },
    getKeyEnv(secret_key) {
      return secret_key.substring(3, 7);
    },
    async initializeData() {
      await this.getApiKeys();
    }
  },
  created() {
    this.initializeData();
  }
};
</script>
<style>
</style>
