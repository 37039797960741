<template>
  <customization />
</template>

<script>
import Customization from "../customization/Customization.vue";
export default {
  components: { Customization },
  name: "CheckoutConfig",
};
</script>