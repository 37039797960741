<template>
  <div>
    <CCard>
      <CCardHeader>
        <span> {{ $t("CUSTOMIZATION.HEADER") }} </span>
        <span class="float-right">
          <CButton
            :disabled="!enablePublish()"
            class="cancel-button"
            @click="toggleUnsavedChangesModal"
          >
            {{ $t("CUSTOMIZATION.CANCEL_BUTTON") }}</CButton
          >
          <CButton
            @click="publishCustomization"
            color="durianprimary"
            :disabled="!enablePublish()"
          >
            <CIcon name="cil-pencil" />
            {{ $t("CUSTOMIZATION.PUBLISH_BUTTON") }}</CButton
          >
        </span>
      </CCardHeader>
      <CRow>
        <CCol xl="1" md="1" class="with-left-top-margin">
          <div>
            <strong> {{ $t("CUSTOMIZATION.LOGO") }} </strong>
            <img
              :title="$t('CUSTOMIZATION.POPOVER_MESSAGE')"
              src="../../assets/img/blue-circle.svg"
              class="info-dot"
            />
          </div>
          <br /><br />
          <div>
            <strong> {{ $t("CUSTOMIZATION.HEADER_COLOR") }}</strong>
          </div>
          <br /><br />
          <div>
            <strong> {{ $t("CUSTOMIZATION.BUTTON_AND_LINK_COLOR") }}</strong>
          </div>
        </CCol>
        <CCol xl="2" md="2" class="with-right-margin">
          <br />
            <file-upload-plus
              v-show="!isUrlPresent"
              :publishCustomization="publishCustomization"
            />
          <image-holder v-show="isUrlPresent" />
          <br />
          <picker-element
            usedBy="header"
            :value="$store.getters.getHeaderColor"
          />
          <br />
          <picker-element
            usedBy="button"
            :value="$store.getters.getButtonAndLinkColor"
          />
        </CCol>
        <CCol xl="2" md="2" class="background-color"></CCol>
        <CCol xl="5" md="5" class="background-color mx-0">
          <br /><checkout /> <br />
        </CCol>
        <CCol xl="1" md="1" class="background-color"></CCol>
      </CRow>
    </CCard>
    <unsaved-changes-modal
      :toggleModal="toggleUnsavedChangesModal"
      :isModalOpen="isUnsavedModalOpen"
      :resetCustomization="resetCustomization"
    />
  </div>
</template>

<script>
import {
  publishCustomization,
  getCustomization,
} from "@/api/customization.api.js";
import * as types from "@/store/mutation-types";
import Checkout from "./components/Checkout.vue";
import PickerElement from "./components/PickerElement.vue";
import FileUploadPlus from "./components/FileUploadPlus.vue";
import ImageHolder from "./components/ImageHolder.vue";
import UnsavedChangesModal from "./components/UnsavedChangesModal";

export default {
  name: "Customization",
  components: {
    Checkout,
    PickerElement,
    FileUploadPlus,
    ImageHolder,
    UnsavedChangesModal,
  },
  data() {
    return {
      actualHeaderColor: null,
      actualButtonColor: null,
      actualLogoUrl: null,
      isUnsavedModalOpen: false,
    };
  },
  methods: {
    addToFormData() {
      const bodyFormData = new FormData();
      bodyFormData.append("logo_file", this.$store.getters.getLogoFile);
      bodyFormData.append("theme_color", this.getHeaderColor);
      bodyFormData.append("accent_color", this.getButtonAndLinkColor);
      bodyFormData.append("logo_width", this.$store.getters.getLogoWidth);
      bodyFormData.append("logo_height", this.$store.getters.getLogoHeight);
      return bodyFormData;
    },
    async publishCustomization() {
      try {
        const bodyFormData = this.addToFormData();
        const response = await publishCustomization(bodyFormData);
        const err = this.verifyHttpResponse(response);
        if (err) {
          this.showToaster(this.$t("CUSTOMIZATION.ERRORS.PUBLISHING"));
          return;
        }
        this.getCustomization();
        this.showSuccessToaster(this.$t("CUSTOMIZATION.SUCCESS.PUBLISH"));
      } catch (error) {
        this.showToaster(this.$t("CUSTOMIZATION.ERRORS.PUBLISHING"));
      }
    },
    async getCustomization() {
      try {
        const response = await getCustomization();
        const data = response.data.data;
        this.actualButtonColor = data.accent_color;
        this.actualHeaderColor = data.theme_color;
        this.actualLogoUrl = data.logo_url;

        this.$store.commit(types.setLogoUrl, this.actualLogoUrl);
        this.$store.commit(
          types.setHeaderColor,
          this.hexToRgb(this.actualHeaderColor)
        );
        this.$store.commit(
          types.setButtonAndLinkColor,
          this.hexToRgb(this.actualButtonColor)
        );
        this.$store.commit(types.setLogoWidth, data.logo_width);
        this.$store.commit(types.setLogoHeight, data.logo_height);
      } catch (error) {
        this.showToaster(this.$t("CUSTOMIZATION.ERRORS.GETTING"));
      }
    },
    resetCustomization() {
      this.toggleUnsavedChangesModal();
      this.getCustomization();
    },
    enablePublish() {
      return (
        this.actualHeaderColor !== this.getHeaderColor ||
        this.actualButtonColor !== this.getButtonAndLinkColor ||
        (this.actualLogoUrl !== this.getLogoUrl && this.getLogoUrl !== null)
      );
    },
    toggleUnsavedChangesModal() {
      this.isUnsavedModalOpen = !this.isUnsavedModalOpen;
    },
  },
  computed: {
    getHeaderColor() {
      return this.rgbToHex(this.$store.getters.getHeaderColor);
    },
    getButtonAndLinkColor() {
      return this.rgbToHex(this.$store.getters.getButtonAndLinkColor);
    },
    getLogoUrl() {
      return this.$store.getters.getLogoUrl;
    },
    isUrlPresent() {
      return this.$store.getters.getLogoUrl !== null;
    },
  },
  mounted() {
    this.getCustomization();
  },
};
</script>

<style Customizationscoped>
.background-color {
  background-color: #f6f6fb;
}
.sidebar {
  margin-top: 10%;
  margin-left: 10%;
}
.with-bottom-margin {
  margin-bottom: 10%;
}
.with-right-margin {
  margin-right: 5%;
}
.with-left-top-margin {
  margin-left: 2%;
  margin-top: 2%;
}
.bold {
  font-weight: bold;
}
.width-larger {
  width: 20%;
}
.picker {
  margin-left: 40%;
  width: 150%;
}
.button-publish {
  width: 10%;
}
.info-dot {
  position: relative;
  margin-left: 2%;
}
.card-header {
  font-size: 18px;
  font-weight: bold;
  margin-top: 1%;
}
.towards-right {
  position: relative;
  left: 70%;
}
.align-with-picker{
  margin-left: 10px;
}
</style>