<template>
  <div class="footer">
    <div
      class="d-flex flex-row justify-content-between padding-around"
    >
      <span class="total">{{ $t("CUSTOMIZATION.TOTAL_PAYMENT") }}</span>
      <span class="total-rp">Rp 211.900</span>
    </div>
    <button class="footer-button" :style="dynamicButtonColor()">
      {{ $t("CUSTOMIZATION.CONTINUE_PAYMENT") }}
    </button>
    <img
      class="footer-image"
      src="../../../assets/img/powered-by-durianpay.svg"
      width="50%"
    />
  </div>
</template>

<script>
export default {
  name: "CheckoutFooter",
  props: { dynamicButtonColor: Function },
};
</script>

<style scoped>
.footer {
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position: relative;
  top: -23px;
}
.footer-button {
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  border-radius: 5px;
  height: 45px;
  font-size: 19px;
}
.footer-image {
  margin-left: 32%;
  width: 33%;
  size: 20%;
}
button {
  width: 32%;
  height: 1.7rem;
  border-radius: 0.2rem;
  font-weight: bold;
  margin-right: 5%;
  font-size: 88%;
}
.with-top-bottom-margin {
  margin-bottom: 3%;
}
.total {
  font-size: 16px;
  padding-top: 10px;
}
.padding-around {
  padding-right: 5%;
  padding-left: 5%;
}
.total-rp {
  padding-top: 10px;
  font-size: 19px;
  font-weight: bold;
}
</style>