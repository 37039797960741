<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.78719 3.71723C8.06323 3.71723 8.28701 3.94101 8.28701 4.21705C8.28701 4.49309 8.06323 4.71687 7.78719 4.71687H2.09878L4.57631 7.21522C4.77249 7.41304 4.77182 7.73224 4.57482 7.92924C4.37723 8.12683 4.05688 8.12683 3.8593 7.92924L0.326627 4.39657C0.227482 4.29742 0.227483 4.13668 0.326627 4.03753L3.8593 0.504862C4.05688 0.307277 4.37723 0.307277 4.57482 0.504862C4.77182 0.701863 4.77249 1.02106 4.57631 1.21888L2.09878 3.71723H7.78719Z"
      :fill="getHeaderTextColor()"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckoutArrow",
  props: {
    getHeaderTextColor: Function,
  },
};
</script>