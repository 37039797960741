<template>
  <div class="lower-table">
    <div class="table-head">{{ $t("CUSTOMIZATION.BILLING_DETAILS") }}</div>
    <table class="d-flex flex-row justify-content-between padding-around">
      <td>
        <tr>
          {{ $t("CUSTOMIZATION.SUBTOTAL") }}
        </tr>
        <tr>
          {{ $t("CUSTOMIZATION.TAX") }}
          10%
        </tr>
        <tr>
          {{ $t("CUSTOMIZATION.SHIPPING_COST") }}
        </tr>
      </td>
      <td>
        <tr class="align-text-right">
          <strong>Rp 229.000</strong>
        </tr>
        <tr class="align-text-right">
          <strong>Rp 22.900</strong>
        </tr>
        <tr class="align-text-right">
          <strong>Rp 10.000</strong>
        </tr>
      </td>
    </table>
    <hr />
    <table
      class="d-flex flex-row justify-content-between padding-around"
    >
      <td>
        <tr class="total">{{ $t("CUSTOMIZATION.TOTAL_PAYMENT") }}</tr>
      </td>
      <td>
        <tr class="total-rp">
          Rp 211.900
        </tr>
      </td>
    </table>
  </div>
</template>

<script>
export default {
  name: "CheckoutAmountInfo",
};
</script>

<style scoped>
.lower-table {
  background: white;
  margin-top: 7px;
  position: relative;
  top: -30px;
}
.table-head {
  margin-left: 5%;
  padding-top: 10px;
  font-weight: bold;
  margin-bottom: 2%;
}
.padding-around {
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: 10px;
}
.total {
  font-size: 16px;
}
.total-rp {
  font-size: 19px;
  font-weight: bold;
}
</style>