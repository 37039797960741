<template>
  <div class="outline pointer-on-hover" @click="openFolderPopup">
    <div class="add-symbol">+</div>
    <input
      type="file"
      accept=".png"
      id="fileInput"
      @input="(event) => getFile(event)"
    />
  </div>
</template>

<script>
import * as types from "@/store/mutation-types";
const FILE_SIZE = 512000;

export default {
  name: "FileUploadPlus",
  data() {
    return {
      file: null,
    };
  },
  methods: {
    openFolderPopup() {
      const el = document.getElementById("fileInput");
      el.click();
    },
    async getFile(event) {
      this.file = event.target.files[0];
      if (this.file.size > FILE_SIZE) {
        this.showToaster(this.$t("CUSTOMIZATION.ERRORS.FILE_SIZE"));
        return;
      }
      if (this.file.type !== "image/png") {
        this.showToaster(this.$t("CUSTOMIZATION.ERRORS.INVALID_FILE"));
        return;
      }
      var output = new Image();
      output.src = URL.createObjectURL(this.file);
      output.onload = () => {
        let actualWidth = output.width;
        let actualHeight = output.height;
        this.widthCalculationAlogrithm(actualWidth, actualHeight);
      };
      this.$store.commit(types.setLogoUrl, output.src);
      this.$store.commit(types.setLogoFile, this.file);
    },
    widthCalculationAlogrithm(width, height) {
      const normalizedWidth = width / 80;
      const normalizedHeight = height / 32;
      const factor = Math.max(normalizedWidth, normalizedHeight);
      const desiredWidth = Math.round(width / factor);
      const desiredHeight = Math.round(height / factor);
      this.$store.commit(types.setLogoWidth, desiredWidth);
      this.$store.commit(types.setLogoHeight, desiredHeight);
    },
  },
};
</script>

<style scoped>
.outline {
  border: 1px solid #dfdfdf;
  width: 35%;
  height: 48px;
  box-shadow: 0px 2px 3px rgba(174, 174, 174, 0.25);
  border-radius: 4px;
  margin-left: 74%;
}
.add-symbol {
  font-size: 30px;
  text-align: center;
  color: #725cf6;
}
#fileInput {
  display: none;
}
</style>