<template>
  <div class="body-content">
    <div class="body-header">{{ $t("CUSTOMIZATION.PRODUCT_PURCHASED") }}</div>
    <br />
    <table class="d-flex flex-row justify-content-between padding-around">
      <td>
        <tr>
          <img width="35" src="../../../assets/img/t-shirt.png" />
        </tr>
        <tr>
          <img
            width="35"
            src="../../../assets/img/clip.png"
            class="pin-image"
          />
        </tr>
      </td>
      <td class="with-margin-right">
        <tr>
          T-shirt - Blue<br /><span class="text-muted"> 2 x Rp 50</span>
        </tr>
        <tr>
          Browser Pin<br /><span class="text-muted"> 2 x Rp 50</span>
        </tr>
      </td>
      <td>
        <tr>
          <strong>Rp 100</strong>
        </tr>
        <br />
        <tr>
          <strong>Rp 100 </strong>
        </tr>
      </td>
    </table>
    <hr />
    <div class="drop-up" :style="computeTextColor">
      {{ $t("CUSTOMIZATION.HIDE_LIST") }}
      <svg
        width="12"
        height="9"
        viewBox="0 0 7 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.1987 3.23255L3.82919 0.863042L1.45968 3.23255"
          :stroke="computeStroke"
          stroke-width="1.0155"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutPurchaseInfo",
  props: { dynamicButtonColor: Function },
  computed: {
    computeTextColor() {
      const styles = this.dynamicButtonColor();
      return {
        color: styles.background,
      };
    },
    computeStroke() {
      const styles = this.dynamicButtonColor();
      return styles.background;
    },
  },
};
</script>

<style scoped>
.body-content {
  background: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  height: 100%;
  position: relative;
  top: -30px;
}
.padding-around {
  padding-right: 5%;
  padding-left: 5%;
}
.pin-image {
  margin-top: 25%;
}
.drop-up {
  font-size: 14px;
  text-align: center;
  padding-bottom: 10px;
  font-weight: bold;
}
.body-header {
  font-size: 14px;
  padding-left: 5%;
  padding-top: 4%;
  font-weight: bold;
}
.with-margin-right {
  margin-right: 48%;
}
</style>