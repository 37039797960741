<template>
  <div class="picker-outline">
    <span>
      <input
        @input="(event) => updateInput(event)"
        class="pointer-on-hover square-with-colour"
        type="color"
        :value="getColor"
      />
    </span>
    <span class="text-muted hash">#</span>
    <input
      @input="(event) => updateInput(event)"
      type="text"
      class="color-text"
      :value="color"
      maxlength="6"
    />
  </div>
</template>

<script>
import * as types from "@/store/mutation-types";
const HEADER = "header";
const BUTTON = "button";

export default {
  name: "PickerElement",
  props: { usedBy: String, value: Object },
  data() {
    return {
      color: null, //hex color without hash
    };
  },
  methods: {
    commitColorToStore(value) {
      if (this.usedBy === HEADER && value !== null)
        this.$store.commit(types.setHeaderColor, value);
      else if (this.usedBy === BUTTON && value !== null) {
        this.$store.commit(types.setButtonAndLinkColor, value);
      }
    },
    updateInput(event) {
      this.color = event.target.value;
      const colorWithHash = this.addHash(this.color);
      if (this.isHexValid(colorWithHash)) {
        this.commitColorToStore(this.hexToRgb(colorWithHash));
      }
    },
  },
  computed: {
    getColor() {
      if (this.usedBy === HEADER)
        return this.rgbToHex(this.$store.getters.getHeaderColor);
      else if (this.usedBy === BUTTON) {
        return this.rgbToHex(this.$store.getters.getButtonAndLinkColor);
      }
    },
  },
  watch: {
    value: function () {
      this.color = this.trimHash(this.rgbToHex(this.value));
    },
  },
};
</script>

<style scoped>
.picker-outline {
  border: 1px solid #dfdfdf;
  width: 95%;
  height: 45px;
  box-shadow: 0px 2px 3px rgba(174, 174, 174, 0.25);
  border-radius: 4px;
  margin-left: 15%;
  margin-bottom: 8%;
}
.square-with-colour {
  margin-left: 5%;
  margin-top: 2.5%;
}
input.color-text {
  position: relative;
  bottom: 25%;
  font-weight: bold;
  border-bottom: 1px solid #dfdfdf;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 45%;
}
input:focus {
  outline-width: 0;
}
input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 23%;
  height: 35px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}
.hash {
  position: relative;
  bottom: 25%;
  margin: 3%;
}
</style>