<template>
  <div class="content" :style="dynamicColor">
    <CRow>
      <CCol md="9" xl="9">
        <div class="checkout-text text-margin">
          <checkout-arrow :getHeaderTextColor="getHeaderTextColor" />
          {{ $t("CUSTOMIZATION.CHECKOUT") }}
        </div>
        <div class="header-message">
          {{ $t("CUSTOMIZATION.ORDER_SUMMARY") }}
        </div>
      </CCol>
      <CCol md="3" xl="3">
        <img
          :style="dynamicLogoShifting"
          :src="getLogoUrl"
          :width="$store.getters.getLogoWidth"
          :height="$store.getters.getLogoHeight"
          v-show="$store.getters.getLogoUrl"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CheckoutArrow from "./CheckoutArrow.vue";
export default {
  components: { CheckoutArrow },
  name: "CheckoutHead",
  methods: {
    getHeaderTextColor() {
      const { r, g, b } = this.$store.getters.getHeaderColor;
      var textColour = (r * 299 + g * 587 + b * 114) / 1000;
      return textColour >= 128 ? "black" : "white";
    },
    dynamicBackgroundColor() {
      const color = this.rgbToHex(this.$store.getters.getHeaderColor);
      return color;
    },
  },
  computed: {
    dynamicColor() {
      return {
        color: `${this.getHeaderTextColor()}`,
        background: `${this.dynamicBackgroundColor()}`,
      };
    },
    getLogoUrl() {
      return this.$store.getters.getLogoUrl;
    },
    dynamicLogoShifting() {
      return {
        position: "relative",
        left: `${70 - this.$store.getters.getLogoWidth}%`,
      };
    },
  },
};
</script>

<style scoped>
.content {
  height: 150px;
  padding-top: 5%;
  padding-bottom: 5%;
  border-radius: 15px;
}
.text-margin {
  margin-left: 7%;
}
.checkout-text {
  font-weight: bold;
  font-size: 16px;
  line-height: 15px;
  margin-top: 5px;
}
.header-message {
  margin-top: 20px;
  font-size: 19px;
  font-weight: bold;
  padding-left: 20px;
}
</style>