<template>
<CCard>
  <CCardHeader class="mt-2">
    <span class="card-title">{{ $t("SETTINGS.WEBHOOKS") }}</span>
    <CButton
      @click="goToCreateWebhook"
      color="durianprimary"
      class="px-2 float-right"
    >
      <CIcon name="cil-library-add" />&nbsp; {{ $t("WEBHOOKS.CREATE") }}
    </CButton>
    <CInput
      :placeholder="$t('COMMON.SEARCH')"
      v-model="webhooksFilter.search"
      @input="getWebhookDetails"
      class="px-2 float-right col-sm-4 mb-0"
    />
    <CButton
      @click="filterModal = true"
      size="sm"
      color="lightborder"
      class="px-2 float-right"
    >
      <CIcon name="cil-filter" />&nbsp;{{ $t("COMMON.FILTERS") }}
    </CButton>
  </CCardHeader>
  <CCardBody>
    <CContainer>
      <CRow>
        <CCol col="12" xl="12" class="p-0">
          <CDataTable class="col-md-12 p-0" hover :items="webhooks" :fields="fields">
            <template #edit="data">
              <td style="width: 10px;">
                <CButton
                  type="Edit"
                  class="regen-btn px-1"
                  @click.prevent="editWebhook(data.item)"
                  >{{ $t("COMMON.EDIT") }}
                </CButton>
              </td>
            </template>
            <template #delete="data">
              <td style="width: 10px;">
                <CButton
                  type="Delete"
                  class="delete-btn px-1"
                  @click="showDeleteConfirmation(data.item)"
                  >{{ $t("COMMON.DELETE") }}
                </CButton>
              </td>
            </template>
          </CDataTable>
          <div class="col-md-11">
            <b-pagination
              size="md"
              align="center"
              :total-rows="totalRows"
              @change="pageChange"
              v-model="pageNumber"
              :per-page="webhooksFilter.limit"
            ></b-pagination>
          </div>

          <CModal
            :title="$t('COMMON.FILTERS_HEADER')"
            color="light"
            :no-close-on-backdrop="false"
            :centered="true"
            :show.sync="filterModal"
          >
            <CContainer>
              <CRow class="mb-3">
                <label class="col-12 col-xl-12">
                  {{ $t("WEBHOOKS.FILTERS.EVENT") }}</label
                >
                <multiselect
                  v-model="filterVal"
                  :options="events"
                  :multiple="true"
                  :placeholder="$t('WEBHOOKS.FILTERS.EVENT')"
                  track-by="name"
                  label="name"
                  class="mx-3"
                  @select="selectFilter"
                  @remove="clearFilter"
                ></multiselect>
              </CRow>
            </CContainer>
            <template #footer>
              <CButton @click="filterModal = false" color="durianprimary">{{
                $t("COMMON.DONE")
              }}</CButton>
            </template>
          </CModal>
          <CModal
            :title="$t('COMMON.DELETE_CONFIRM')"
            color="danger"
            :no-close-on-backdrop="false"
            :centered="true"
            :show.sync="deleteConfirmationModal"
          >
            <CContainer>
              <label class="modal-msg">{{
                $t("WEBHOOKS.DELETE_CONFIRM_MSG")
              }}</label>
            </CContainer>
            <template #footer>
              <CButton
                @click="deleteConfirmationModal = false"
                color="lightborder"
                >{{ $t("COMMON.CANCEL") }}</CButton
              >
              <CButton @click.prevent="deleteWebhook()" color="lightborder">{{
                $t("COMMON.OK")
              }}</CButton>
            </template>
          </CModal>
          <EditWebhook
            v-if="showEditModal"
            @updatelist="getWebhookDetails"
            :editWebhookModal.sync="showEditModal"
            :id="id"
          />
        </CCol>
      </CRow>
    </CContainer>
  </CCardBody>
</CCard>
</template>

<script>
import {
  listWebhook,
  deleteWebhook,
} from "@/api/webhooks.api.js";
import Multiselect from "vue-multiselect";
import EditWebhook from "./EditWebhook";
import { sandboxEventEmitter } from "@/../config.js";

const COMPONENT_NAME = "Webhooks";
export default {
  components: {
    Multiselect,
    EditWebhook,
  },
  name: COMPONENT_NAME,
  data() {
    return {
      filterModal: false,
      deleteConfirmationModal: false,
      showEditModal: false,
      fields: [
        { key: "name", label: this.$t("WEBHOOKS.TABLE.NAME") },
        { key: "url", label: this.$t("WEBHOOKS.TABLE.URL") },
        { key: "event", label: this.$t("WEBHOOKS.TABLE.EVENT") },
        { key: "is_primary", label: this.$t("WEBHOOKS.TABLE.PRIMARY") },
        { key: "edit", label: "" },
        { key: "delete", label: "" },
      ],
      webhooksFilter: {
        skip: 0,
        limit: 50,
        filters: [],
        from: "",
        to: "",
        search: "",
      },
      events: [],
      webhooks: [],
      filterVal: null,
      pageNumber: 1,
      totalRows: 0,
      filterMap: new Map(),
      id: "",
    };
  },
  methods: {
    goToCreateWebhook() {
      this.$router.push("settings/webhooks/new");
    },
    selectFilter(val) {
      if (val.column === "event") {
        if (this.filterMap.has(val.column)) {
          this.filterMap.get(val.column).push(val.name);
        } else {
          this.filterMap.set(val.column, [val.name]);
        }
      } else {
        this.filterMap.set(val.column, val.name);
      }

      this.webhooksFilter.skip = 0;
      this.generateFiltersArray();

      this.getWebhookDetails();
    },
    clearFilter(val) {
      if (val.column === "event" && this.filterMap.get(val.column).length > 1) {
        let removeIndex = -1;
        this.filterMap.get(val.column).find((v, i) => {
          if (v === val.name) {
            removeIndex = i;
          }
          if (removeIndex != -1) {
            this.filterMap.get(val.column).splice(removeIndex, 1);
          }
        });
      } else {
        this.filterMap.delete(val.column);
      }
      this.generateFiltersArray();
      this.getWebhookDetails();
    },
    generateFiltersArray() {
      this.webhooksFilter.filters = Array.from(
        this.filterMap,
        ([key, value]) => {
          value = JSON.stringify(value);
          return {
            key,
            value,
          };
        }
      );
    },
    showDeleteConfirmation(item) {
      this.id = item.id;
      this.deleteConfirmationModal = true;
    },
    deleteWebhook() {
      deleteWebhook(this.id)
        .then((response) => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.showSuccessToaster(response.data.data);
            this.deleteConfirmationModal = false;
            this.getWebhookDetails();
          }
        })
        .catch(this.handleAPIError);
    },
    editWebhook(item) {
      this.id = item.id;
      this.showEditModal = true;
    },
    getWebhookDetails() {
      const { skip, limit, from, to, search, filters } = this.webhooksFilter;
      let paramsMap = new Map();
      paramsMap["skip"] = skip;
      paramsMap["limit"] = limit;
      paramsMap["from"] = from;
      paramsMap["to"] = to;
      paramsMap["search"] = search;
      paramsMap["filters"] = JSON.stringify(filters);
      listWebhook(this.generatePostParams(paramsMap))
        .then((response) => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.webhooks = response.data.data.webhooks;
            this.totalRows = response.data.data.count;
          }
        })
        .catch(this.handleAPIError);
    },
    pageChange(val) {
      this.webhooksFilter.skip = (val - 1) * this.webhooksFilter.limit;
      this.getWebhookDetails();
    },
    getEvents() {
      let events = this.getWebhookEvents();
      events.forEach((eventName) => {
        let event = {};
        event.name = eventName;
        event.column = "event";
        this.events.push(event);
      });
    },
  },
  mounted() {
    this.getWebhookDetails();
    this.getEvents();
  },
  created() {
    this.$eventHub.$on(sandboxEventEmitter.prefix + COMPONENT_NAME, this.getWebhookDetails);
    this.$eventHub.$on(sandboxEventEmitter.prefix + COMPONENT_NAME, this.getEvents);
  },
  beforeDestroy(){
    this.$eventHub.$off(sandboxEventEmitter.prefix + COMPONENT_NAME);
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

