import { httpClient } from "./httpClient";

const PUBLISH_CUSTOMIZATION_PATH = "/dashboard/merchants/checkout_customization";
const GET_CUSTOMIZATION_PATH = "/dashboard/merchants/config";

const publishCustomization = async (formData) => {
    let config = {
        headers: { "Content-Type": "multipart/form-data" }
    }
    return await httpClient.dashboardService.put(PUBLISH_CUSTOMIZATION_PATH, formData, config);
}

const getCustomization = async () => {
    return await httpClient.dashboardService.get(GET_CUSTOMIZATION_PATH);
}

export {
    publishCustomization,
    getCustomization
}