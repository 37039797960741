<template>
  <CModal :show="isModalOpen" :centered="true">
    <div slot="header" />
    <div slot="header-wrapper" />
    <div slot="body-wrapper" class="padded">
      <div>
        <h5 class="header">{{ $t("CUSTOMIZATION.MODAL_HEADER") }}</h5>
      </div>
      <div>
        {{ $t("CUSTOMIZATION.MODAL_MESSAGE_1") }}<br />
        {{ $t("CUSTOMIZATION.MODAL_MESSAGE_2") }}
      </div>
      <div class="right margin-top">
        <CButton @click="toggleModal">
          <strong>
            <h5>{{ $t("COMMON.CANCEL") }}</h5>
          </strong>
        </CButton>
        <CButton
          color="durianprimary"
          @click="resetCustomization"
          class="reset-button"
        >
          <strong>
            <h5>{{ $t("CUSTOMIZATION.CONFIRM_BUTTON") }}</h5>
          </strong>
        </CButton>
      </div>
    </div>
    <div slot="footer" />
  </CModal>
</template>

<script>
export default {
  name: "UnsavedChangesModal",
  props: {
    isModalOpen: Boolean,
    resetCustomization: Function,
    toggleModal: Function,
  },
};
</script>
<style scoped>
.margin-top {
  margin-top: 16px;
}
.padded {
  padding-right: 32px;
  padding-left: 32px;
}
.reset-button{
  width: 50%;
}
.right{
  margin-left: 60%;
}
</style>